<script lang="ts" setup>
const props = defineProps<{
  text: string
}>()

const customText = computed(() => {
  let custom = props.text ? props.text : ''
  custom = custom.replaceAll('<h1', '<div class="title-h1"')
  custom = custom.replaceAll('<h2', '<div class="title-h2"')
  custom = custom.replaceAll('<h3', '<div class="title-h3"')
  custom = custom.replaceAll('<h4', '<div class="title-h4"')
  custom = custom.replaceAll('<h5', '<div class="title-h5"')
  custom = custom.replaceAll('<h6', '<div class="title-h6"')
  custom = custom.replaceAll('</h1>', '</div>')
  custom = custom.replaceAll('</h2>', '</div>')
  custom = custom.replaceAll('</h3>', '</div>')
  custom = custom.replaceAll('</h4>', '</div>')
  custom = custom.replaceAll('</h5>', '</div>')
  custom = custom.replaceAll('</h6>', '</div>')
  return custom
})
</script>

<template>
  <div class="custom-text" data-test="text">
    <div v-html="customText" />
  </div>
</template>

<style lang="scss" scoped>
.custom-text {
  @apply font-opensans text-16 font-normal;

  :deep(img) {
    margin: 0 auto;
  }

  :deep(ul) {
    list-style: disc;
    margin-left: 20px;
  }

  :deep(p) {
    @apply font-opensans text-16 font-normal;
  }

  :deep(.title-h1) {
    @apply font-montserrat text-42 font-semibold lg:text-62;
  }

  :deep(.title-h2),
  :deep(.title-h3) {
    @apply font-montserrat text-30 font-semibold lg:text-48;
  }

  :deep(.title-h4) {
    @apply font-montserrat text-24 font-semibold;
  }

  :deep(.title-h5),
  :deep(.title-h6) {
    @apply font-montserrat text-20 font-normal;
  }

  :deep(a) {
    @apply text-secondary-500 hover:text-[#51ADAD];
  }
}
</style>
